<template>
    <div class="CategoryEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                ref="category"
                label-width="80px"
                :model="category"
                @submit.native.prevent
                :rules="rules"
                size="small"
            >
                <el-form-item label="编码" prop="code">
                    <el-input readonly v-model="category.code" />
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input v-model="category.name" />
                </el-form-item>
                <el-input readonly v-model="category.parentCode" style="display: none" />
                <el-form-item label="等级">
                    <el-input readonly v-model="category.level" />
                </el-form-item>
                <el-form-item label="显示顺序" prop="showIndex">
                    <el-input-number
                        v-model.number="category.showIndex"
                        controls-position="right"
                        :min="1"
                        :max="100"
                        :precision="0"
                    />
                </el-form-item>
                <el-form-item prop="rst" />
                <el-form-item>
                    <el-button type="primary" @click="save" size="small">保存</el-button>
                    <el-button @click="reset" size="small">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'CategoryEdit',
    props: ['form'],
    data() {
        return {
            category: {},
            backUp: {
                code: {},
                name: {},
                level: {},
                showIndex: {},
            },
            rules: {
                code: [{ required: true, message: '编码必填', trigger: 'blur' }],
                name: [{ required: true, message: '请填写类目名称', trigger: 'blur' }],
                showIndex: [{ required: true, type: 'integer', message: '请输入整数显示顺序', trigger: 'blur' }],
            },
        };
    },
    mounted() {
        this.backUp.code = this.form.code;
        this.backUp.name = this.form.name;
        this.backUp.level = this.form.level;
        this.backUp.showIndex = this.form.showIndex;
        this.backUp.parentCode = this.form.parentCode;
        this.category = JSON.parse(JSON.stringify(this.backUp));
    },
    methods: {
        save() {
            this.$refs['category'].validate((valid) => {
                if (!valid) return;
                UrlUtils.PatchRemote(
                    this,
                    '/goods/category/patch_c/' + this.category.code,
                    this.category,
                    null,
                    (res) => {
                        this.goBackAndReload();
                        this.$message.success('保存成功');
                    }
                );
            });
        },
        reset() {
            this.category = JSON.parse(JSON.stringify(this.backUp));
            this.$refs['category'].validate();
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
